import React from "react";
import { useNavigate } from "react-router-dom";

const BlogCard = ({ blog, blog_type }) => {
    const navigate = useNavigate();
    const getLatestTag = () => {
        return (
            <div className="bg-[#3940A0] text-[#fff] font-PoppinsRegular absolute top-[1vw] left-[1vw] rounded-[6px] px-[1vw] py-[0.2vw] max-sm:bottom-[4vw] max-sm:left-[2vw] max-sm:py-[1vw] max-sm:px-[4vw] z-10">
               <button>Latest</button>
           </div>
        );
    };

    return (
        <>
            <div
                onClick={() => {
                    navigate(`/${blog.url}/${blog.id}`);
                }}
                className="flex flex-col border border-[#D0D5DD] border-solid  rounded-[10px] cursor-pointer  overflow-hidden m-[1vw] max-sm:my-[0.5vw]"
            >
                <a
                    className="w-full h-[20vw] relative max-sm:h-[50vw]"
                    href={`/${blog.url}/${blog.id}`}
                >
                    <img className="h-full w-full" src={blog.most_viewed_blog_image_url} alt="" />
                    {blog_type === "latest" ? getLatestTag() : <></>}
                </a>
                <div className="flex flex-col p-[1vw] max-sm:p-[3vw] bg-[#FFFF]">
                    <p className="font-PoppinsSemiBold text-[1.1vw] py-[1vw] h-[4vw] line-clamp-2 max-sm:text-[3vw] max-sm:h-[12vw]">
                        {blog.title}
                    </p>
                    {/* <div className="text-left font-light line-clamp-2 w-[25vw]  font-PoppinsRegular text-[#4f4f4f] text-[0.9vw] max-sm:text-[2vw] max-sm:w-full">
                        {blog.subtitle}
                    </div> */}
                    <div className="flex pt-[2vw] items-center max-sm:pt-[5vw]">
                        <div className="w-12 h-12 max-sm:w-10 max-sm:h-10">
                            <img
                                class="rounded-full "
                                src={blog.author_avatar_url}
                                alt="user image"
                            />
                        </div>

                        <div className="flex flex-col pl-4">
                            <p className="font-PoppinsRegular text-[#000] text-[1vw] font-normal text-left max-sm:text-[2.5vw]">
                                {blog.author}
                            </p>
                            <div className="flex  items-center">
                                <p className="font-light font-PoppinsRegular text-[#4f4f4f] text-[0.8vw] max-sm:text-[2.5vw]">
                                    {blog.date_created}
                                </p>
                                <p className="font-light font-PoppinsRegular text-[#4f4f4f] text-[0.8vw]  pl-[2vw] max-sm:text-[2.5vw] max-sm:pl-[4vw]">
                                    {blog.read_time_min + " Minutes"}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogCard;
